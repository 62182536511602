<template>
  <van-list 
    class="recharge_list" 
    v-loading="loading"
    :finished="finished" 
    :loading-text="$sys('warn.loading')"
    :finished-text="$sys('warn.noMore')" 
    offset="20" 
    :immediate-check="false" 
    error-text="" 
    @load="onLoad"
  >
    <div v-for='item in tableList' :key='item.orderNumber'>
      <div class='item'>
        <p class='date'>
          {{ $sys('mall.date') }}：<span class="val">{{ item.createTime }}</span>
        </p>
        <p class='name ellipse'>
          {{ $sys('mall.productName') }}：<span>{{ item.commodityName }}</span>
        </p>
        <p class='orderNumber'>
          {{ $sys('mall.orderNumber') }}：<span class='number'>{{ item.orderCode }}</span>
        </p>
        <p class='amount'>
          {{ item.orderPrizeShow }}
        </p>
        <div class='status'>
          <p :class="{
            'fail': item.orderStatus == 0 || item.orderStatus == 11,
            'ok': item.orderStatus == 1 || item.orderStatus == 2,
            'invalid': item.orderStatus == 3
          }">{{ item.status ? $sys(`mall.status.${item.status}`) : '' }}</p>
        </div>

        <p class='opt-panel'>
          <a href='javascript:;' v-if='item.canCancel' class='cancel-order cancel'
            @click="$emit('onCancel', item.orderCode)">
            {{ $sys('mall.cancelOrder') }}
          </a>
        </p>

      </div>
    </div>
  </van-list>
</template>

<script>
import { List } from 'vant'
import { mapState } from 'vuex'

export default {
  components: {
    [List.name]: List
  },
  data() {
    return {
      tableList: []
    }
  },
  computed: {
    ...mapState('order', ['orderList', 'orderPage', 'loading']),
    finished() {
      return this.tableList.length >= this.orderPage.total
    }
  },
  methods: {
    onLoad() {
      if (this.loading) {
        return
      }
      this.$emit('onPage', { current: ++this.orderPage.current })
    }
  },
  watch: {
    orderList(arr) {
      if (this.orderPage.current === 1) {
        this.tableList = arr
      } else {
        this.tableList = [...this.tableList, ...arr]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.recharge_list {
  font-size: 28px;
  line-height: 45px;
  color: #904400;
  background-color: #F8F8FA;
  box-sizing: border-box;
  padding: 30px;
  min-height: 100px;

  .orderNumber {
    font-weight: 400;
    font-size: 24px;
    color: #666666;

    .number {
      letter-spacing: 0;
    }
  }

  .item {
    padding: 20px;
    position: relative;
    background-color: #fff;
    margin: 20px 0;

    &:hover {
      //background-color: #fff5e9;
    }
  }

  .date {
    max-width: 450px;
    font-weight: 400;
    font-size: 24px;
    color: #666666;

    .val {
      white-space: nowrap;
    }
  }

  .name {
    font-weight: 600;
    font-size: 28px;
    color: #333333;
    max-width: 80%;
  }

  .amount {
    position: absolute;
    right: 20px;
    top: 63px;
    font-size: 28px;
    font-weight: 600;
    color: #333333;
    text-align: right;
  }

  .status {
    position: absolute;
    right: 20px;
    top: 10px;
    font-size: 28px;
    line-height: 40px;
    margin-top: 12px;
    text-align: right;

    .ok {
      font-weight: 500;
      font-size: 24px;
      color: #25A725;
    }

    .fail {
      font-weight: 500;
      font-size: 24px;
      color: #F53030;
    }

    .invalid {
      font-weight: 500;
      font-size: 24px;
      color: #808080;
    }
  }

  .opt-panel {
    text-align: right;
    margin: 20px 5px;
  }

  .cancel {
    padding: 10px 20px;
    font-weight: 400;
    font-size: 26px;
    color: #FD7B10;
    border-radius: 30px;
    border: 1px solid #FD7B10;
  }
}
</style>